import React, {useState} from "react";

import FormItem from "../FormItem";
import FormProgressBar from "../FormProgressBar";

const FormStart = ({setForm, formData, navigation, contentData, language}) => {

  const {nickName, shortTermMembership} = formData;
  const {next} = navigation;
  const content = contentData.startscreen;
  const [formStepIsValid, setFormStepIsValid] = useState(false);

  // check if formStep is valid
  if (!formStepIsValid) {
    if (nickName) {
      setFormStepIsValid(true);
    }
  } else {
    if (!nickName) {
      setFormStepIsValid(false);
    }
  }
  const triggerErrorNotification = () => {
    console.log('NED OIS AUSGFÜLLT!')
  }

  const shortTermHeading = {
    de: 'Kurzmitgliedschaft',
    en: 'Short-Term Membership',
  }
  const shortTermLabelText= {
    de: 'Ja, ich möchte mich als Kurzmitglied für eine pauschale Gebühr von 50€ für ein Semester anmelden.',
    en: 'Yes, I\'d like to sign up as a short-term member for a one-time fee of 50 € for one semester.',
  }

  return (
    <>
      <div className="form">
        <FormProgressBar step="start"/>

        <div className="form__content">
          <div dangerouslySetInnerHTML={{__html: content.introText[language]}}/>

          <div className={'short-term-membership-info'}>
            <h3>{shortTermHeading[language]}</h3>
            <p dangerouslySetInnerHTML={{__html: content.shortTermInfo[language]}}></p>
            <p>We're now offering special deals for short-term members. For a "flat fee" of 50€ you can access all our
              practises for one semester. Please check this checkbox if you'd like to register as a short-term member:</p>
            <FormItem
              type={'checkbox'}
              label={shortTermLabelText[language]}
              name="shortTermMembership"
              value={shortTermMembership}
              onChange={setForm}
            />
          </div>

          <FormItem
            label={content.textNickName[language]}
            name="nickName"
            value={nickName}
            onChange={setForm}
          />

          <div style={{display: 'flex', justifyContent: 'center', margin: '25px 0'}}>
            <button
              className={formStepIsValid ? 'form-navigation__next-button--validated' : 'form-navigation__next-button'}
              onClick={formStepIsValid ? () => {
                next();
                window.scrollTo(0, 0);
              } : triggerErrorNotification}
            >
            {language === 'de' ? 'Weiter' : 'Next'}
            </button>
          </div>
        </div>

      </div>
    </>
  );
};

export default FormStart;
