import React, {useState} from "react";

// import FormItem from "../FormItem";
import FormNavigation from "../FormNavigation";
import FormProgressBar from "../FormProgressBar";
import PrivacyAgreementCard from "./PrivacyAgreementCard";
import '../../assets/scss/privacy.scss';
import FormNotification from "../FormNotification";

const PrivacyAgreement = ({setForm, formData, navigation, navigationData, contentData, language}) => {
  const content = contentData.privacy;
  const [formStepIsValid, setFormStepIsValid] = useState(false);

  // check if formStep is valid
  if (!formStepIsValid) {
    let newValidationStatus = true;
    for (var i = 0; i < contentData.privacy.paragraphs.length; i++) {
      if (formData['privacyAgreement_' + i] === false) {
        newValidationStatus = false;
      }
    }
    if (newValidationStatus) {
      setFormStepIsValid(true);
    }
  } else {
    let newValidationStatus = false;
    for (i = 0; i < contentData.privacy.paragraphs.length; i++) {
      if (formData['privacyAgreement_' + i] === false) {
        newValidationStatus = true;
      }
    }
    if (newValidationStatus) {
      setFormStepIsValid(false);
    }
  }

  return (
    <div className="form">
      <FormProgressBar step="privacy"/>

      <div className="form__content">
        {content.introText[language]}


        <div className="form__privacy">
          {
            content.paragraphs.map((paragraph, index) =>
              <div key={index} className="form__privacy--paragraph">
                <PrivacyAgreementCard
                  heading={paragraph.heading[language]}
                  text={paragraph.text[language]}
                  index={index}
                  value={formData['privacyAgreement_' + index]}
                  setForm={setForm}
                />

              </div>
            )
          }
        </div>
      </div>

      {navigationData.formSubmitResponse &&
        <FormNotification notification={navigationData.formSubmitResponse}/>
      }

      <FormNavigation {...{navigation, navigationData, language, formStepIsValid}} />
    </div>
  );
};

export default PrivacyAgreement;
