import React, {useState} from "react";
import FormProgressBar from "../FormProgressBar";
import FormNavigation from "../FormNavigation";
import '../../assets/scss/number-select.scss';
import FormNotification from "../FormNotification";

export default function NumberSelection({
                                          setForm,
                                          formData,
                                          navigation,
                                          navigationData,
                                          language,
                                          playerNumbers,
                                          contentData
                                        }) {
  const {jerseyNumber, shortTermMembership} = formData;
  const content = contentData.number;
  const isShortTermMembership = shortTermMembership;
  const [allNumbersUnlocked, setAllNumbersUnlocked] = useState(isShortTermMembership);
  const [selectedPlayerNumber, setSelectedPlayerNumber] = useState(jerseyNumber);
  const [formStepIsValid, setFormStepIsValid] = useState(false);

  // check if formStep is valid
  if (!formStepIsValid) {
    if (jerseyNumber) {
      setFormStepIsValid(true);
    }
  } else {
    if (!jerseyNumber) {
      setFormStepIsValid(false);
    }
  }

  const heading = {
    de: 'Wähle deine Rückennummer',
    en: 'Choose your jersey-number',
  }

  return (
    <div className="form">
      <FormProgressBar step="number"/>

      <div className="form__content">
        <b>{heading[language]}</b><br/>

        {!isShortTermMembership && (
          <>
            <p dangerouslySetInnerHTML={{__html: content.introText[language]}}/>
            <br/>
            <label htmlFor={'unlock-all-numbers-checkbox'} className={'number__unlock-all-box'}>
              <div className={'number__unlock-all-checkbox'}>
                <input id={'unlock-all-numbers-checkbox'} type={'checkbox'} value={allNumbersUnlocked}
                       onChange={() => setAllNumbersUnlocked((current) => !current)}/>
              </div>
              <div className={'number__unlock-all-checkbox-description'}>
                {language === 'de' ? 'Ich benötige keine eigene Nummer.' : 'I don\t need my own number.'}
                <br/><span
                className={'number__unlock-all-checkbox-description--small'}>{language === 'de' ? '(Schaltet alle Nummern frei)' : '(unlocks all numbers)'}</span>
              </div>
            </label>
          </>
        )}


        {isShortTermMembership && (
          <>
            <p>
              {content.shortTermInfo[language]}
            </p>
            <br/><br/>
          </>
        )}

        <div className={'number-select'}>
          {playerNumbers.map((playerNumber, index) => {
            const isAvailable = allNumbersUnlocked ? true : !playerNumber;
            return (
              <React.Fragment key={index}>
                <label htmlFor={'jerseyNumber-' + index}
                       className={'number-select__number' + (isAvailable ? ' available' : '') + (selectedPlayerNumber && parseInt(selectedPlayerNumber) === index ? ' selected' : '')}>
                  <input type={"radio"} name={'jerseyNumber'} id={'jerseyNumber-' + index} value={index}
                         disabled={!isAvailable}
                         defaultChecked={selectedPlayerNumber && parseInt(selectedPlayerNumber) === index}
                         onChange={(event) => {
                           setSelectedPlayerNumber(event.target.value);
                           setForm(event);
                         }} style={{
                    visibility: "hidden",
                    transform: 'translate(0, -9999px)',
                    position: 'absolute'
                  }}/> {index}
                </label>
              </React.Fragment>
            );
          })}
        </div>
        <br/>
        {selectedPlayerNumber &&
          <div>
            <div className={'number__selected-number-info'}>
              {language === 'de' ? 'Gewählte Nummer: ' : 'Chosen number: '} <span
              className={'number__selected-number-info--large'}>{selectedPlayerNumber}</span>
            </div>
            {!isShortTermMembership && allNumbersUnlocked && playerNumbers[selectedPlayerNumber] &&
              <div className={'number__selected-number-info--small'}>
                {language === 'de' ? 'Diese Nummer wird auch bereits von anderen Personen im Verein getragen.' : 'This number is also taken by other players in our club.'}
              </div>
            }
          </div>
        }
      </div>

      {navigationData.formSubmitResponse &&
        <FormNotification notification={navigationData.formSubmitResponse}/>
      }

      <FormNavigation {...{formData, navigation, navigationData, language, formStepIsValid}} />
    </div>
  )
}