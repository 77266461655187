import React, {useState} from "react";
import FormItem from "../FormItem";

const PrivacyAgreementCard = ({heading, text, index, setForm, value}) => {
  const [isActive, setIsActive] = useState(false);
  const [isChecked, setIsChecked] = useState(value);

  const checkAndCollapse = () => {
    if (!isChecked) {
      setIsChecked(true);
      setIsActive(false);
    } else {
      setIsChecked(false);
    }
  };

  return (
    <div className={'privacy-item' + (isActive ? ' expanded' : '') + (isChecked ? ' checked' : '')}>
      <div
        className="privacy-item__header"
        onClick={() => setIsActive(!isActive)}
      >
        <div className="privacy-item__header--title">
          <div className="privacy-item__header--checkbox">
            <div className="privacy-item__header--checkbox-circle"/>
            <div className="privacy-item__header--checkbox-check"/>
          </div>
          <div className="privacy-item__header--heading">
            {heading}
          </div>
        </div>
        <div>{isActive ? '-' : '+'}</div>
      </div>
      {isActive &&
        <div className="privacy-item__content">
          <div dangerouslySetInnerHTML={{__html: text}}/>
          <FormItem
            onClick={checkAndCollapse}
            label="Ich stimme zu"
            name={'privacyAgreement_' + index}
            type="checkbox"
            defaultChecked={value}
            value={value}
            onChange={setForm}/>
        </div>
      }
    </div>
  );
};

export default PrivacyAgreementCard;
