import React from "react";
import FormProgressBar from "../FormProgressBar";

const FormSubmit = ({navigationData, contentData, language, formData}) => {
  const content = contentData.submit;
  const {shortTermMembership} = formData;
  console.log(formData);

  if (navigationData.formSubmitResponse === 'error') {
    return (
      <div className="form">
        <FormProgressBar step="submit"/>

        <div className="form__content">
          <div dangerouslySetInnerHTML={{__html: content.error[language]}}/>
        </div>

      </div>
    );
  }

  const successText = shortTermMembership ? content.successShortTerm[language] : content.success[language];
  return (
    <div className="form">
      <FormProgressBar step="submit"/>

      <div className="form__content">
        <div dangerouslySetInnerHTML={{__html: successText}}/>
      </div>

    </div>
  );
};

export default FormSubmit;
