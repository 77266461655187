import React from "react";

const FormNotification = ({notification}) => {
  return (
    <div className="form__notification">
      <div className="form__notification-icon"/>
      {notification}
    </div>
  );
};

export default FormNotification;
