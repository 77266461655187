import React from "react";

const FormProgressBar = ({step}) => {
  if (step === 'submit' || step === 'start') {
    return '';
  }

  return (
    <div className="form__progress-bar">
      <div className={`form__progress-bar--item${step === 'personal' ? ' active' : ''}`}>1</div>
      <div className={`form__progress-bar--item${step === 'number' ? ' active' : ''}`}>2</div>
      <div className={`form__progress-bar--item${step === 'privacy' ? ' active' : ''}`}>3</div>
      <div className={`form__progress-bar--item${step === 'review' ? ' active' : ''}`}>4</div>

      <div className="form__progress-bar--line"></div>
    </div>
  )
};

export default FormProgressBar;
