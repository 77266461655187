import React from "react";

const FormItem = ({label, children, type = "text", ...otherProps}) => {
  if (type === 'checkbox') {
    return (
      <label className="form__item form__item--checkbox">
        <div className={'checkbox-wrapper'}>
          <input type={type} {...otherProps} />
        </div>
        <div>{label}</div>
      </label>
    );
  }
  return (
    <div className="form__item">
      <label>{label}</label>
      <input type={type} {...otherProps} />
    </div>
  );
};

export default FormItem;
