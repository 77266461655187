import React, {useState} from "react";
import MultiStepForm from "./form-components/MultiStepForm";

const MemberWelcomeForm = () => {
  const [chosenLanguage, setChosenLanguage] = useState(null);

  const LanguageSelection = () => (
    <div className="language-selection">
      <div className="language-selection__text">
        Sprache wählen: / Choose language:
      </div>
      <button onClick={de_chosen} >DEUTSCH</button>
      &nbsp;&nbsp;
      <button onClick={en_chosen} >ENGLISH</button>
    </div>
  )

  const de_chosen = () => (
    setChosenLanguage('de')
  )

  const en_chosen = () => (
    setChosenLanguage('en')
  )

  if (!chosenLanguage) {
    return (
      <>
        <h1>Mitglied werden/ Become a member</h1>
        <LanguageSelection/>
      </>
    );
  }

  return (
    <MultiStepForm language={chosenLanguage}/>
  );
}


export default MemberWelcomeForm;
