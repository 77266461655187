import React, {useState} from "react";
import {useForm, useStep} from "react-hooks-helper";
import '../assets/scss/form.scss';
import ContentService from "../services/ContentService";

import Loader from "../Loader";
import FormStart from "./form-steps/FormStart";
import PersonalData from "./form-steps/PersonalData";
import PrivacyAgreement from "./form-steps/PrivacyAgreement";
import FormReview from "./form-steps/FormReview";
import FormSubmit from "./form-steps/FormSubmit";
import NumberSelection from "./form-steps/NumberSelection";

const steps = [
  {id: "start"},
  {id: "personal"},
  {id: "number"},
  {id: "privacy"},
  {id: "review"},
  {id: "submit"},
];

const defaultData = {
  firstName: '',
  lastName: '',
  nickName: '',
  birthday: '',
  gender: '',
  email: '',
  phone: '',
  jerseyNumber: '',
  language: '',
};

// const defaultData = {
//     firstName: "Fränzis",
//     lastName: "Gärtner",
//     nickName: "Fränz",
//     birthday: '2010-01-23',
//     email: "fraenz@augaertner-ultimate.at",
//     phone: "+436782345678",
//     jerseyNumber: '00',
//     language: 'de',
// };

const MultiStepForm = (props) => {
  const [contentData, setContentData] = useState(null);
  const [playerNumbers, setPlayerNumbers] = useState(null);
  const [privacyAgreementsInitialized, setPrivacyAgreementsInitialized] = useState(false);
  const [formSubmitResponse, setFormSubmitResponse] = useState(null);
  const [formData, setForm] = useForm(defaultData);
  const {step, navigation} = useStep({initialStep: 0, steps});
  const {id} = step;
  const language = props.language;
  const navigationData = {steps, step, formSubmitResponse, setFormSubmitResponse}
  formData.language = props.language;

  if (!contentData) {
    const contentService = new ContentService();
    contentService.retrieveAcfOptions().then(
      (result) => {
        console.log(result);
        setContentData(result.data.acf);
        setPlayerNumbers(result.data.playerNumbers);
      },
      (error) => {
        console.log(error);
      }
    )
    return (
      <div className="form"><Loader/></div>
    );
  }

  const formProps = {formData, setForm, navigation, navigationData, contentData, playerNumbers, language};

  // Initialize privacy paragraphs
  if (!privacyAgreementsInitialized) {
    //const [index, paragraph] of contentData.privacy.paragraphs
    for (var i = 0; i < contentData.privacy.paragraphs.length; i++) {
      formData['privacyAgreement_' + i] = false;
    }
    setPrivacyAgreementsInitialized(true);
  }

  switch (id) {
    case "start":
      return <FormStart {...formProps} />;
    case "personal":
      return <PersonalData {...formProps} />;
    case "number":
      return <NumberSelection {...formProps} />;
    case "privacy":
      return <PrivacyAgreement {...formProps} />;
    case "review":
      return <FormReview {...formProps} />;
    case "submit":
      return <FormSubmit {...formProps} />;
    default:
      return null;
  }
};

export default MultiStepForm;
