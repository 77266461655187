import React from "react";
import FormNavigation from "../FormNavigation";
import FormProgressBar from "../FormProgressBar";

const FormReview = ({setForm, formData, navigation, navigationData, contentData, language}) => {
  const {
    firstName,
    lastName,
    nickName,
    phone,
    email,
    birthday,
    jerseyNumber,
    gender,
    shortTermMembership
  } = formData;
  const {go} = navigation;
  const content = contentData.review;

  let niceGender = gender === 'w' ? 'weiblich' : (gender === 'm' ? 'männlich' : 'divers');

  return (
    <div className="form">
      <FormProgressBar step="review"/>

      <div className="form__content">
        Danke, {nickName}!<br/>
        {content.introText[language]}

        <div className="form__review">

          <div className="form__review-item">
            <div className="label">{language === 'de' ? 'Vorname' : 'First name'}</div>
            <div className="value">{`${firstName}`}</div>
          </div>

          <div className="form__review-item">
            <div className="label">{language === 'de' ? 'Nachname' : 'Last name'}</div>
            <div className="value">{`${lastName}`}</div>
          </div>

          <div className="form__review-item">
            <div className="label">{language === 'de' ? 'Telefon' : 'Phone'}</div>
            <div className="value">{`${phone}`}</div>
          </div>

          <div className="form__review-item">
            <div className="label">{language === 'de' ? 'E-Mail-Adresse' : 'Mail address'}</div>
            <div className="value">{`${email}`}</div>
          </div>

          <div className="form__review-item">
            <div className="label">{language === 'de' ? 'Geburtstag' : 'Birthdate'}</div>
            <div className="value">{`${birthday}`}</div>
          </div>

          <div className="form__review-item">
            <div className="label">{language === 'de' ? 'Geschlecht': 'gender'}</div>
            <div className="value">{`${niceGender}`}</div>
          </div>

          {jerseyNumber && (
            <div className="form__review-item">
              <div className="label">{language === 'de' ? 'Rückennummer' : 'Jersey Number'}</div>
              <div className="value">{`${jerseyNumber}`}</div>
            </div>
          )}

          {shortTermMembership && (
            <div className="form__review-item">
              <div className="label">{language === 'de' ? 'Mitgliedschaft': 'Membership type'}</div>
              <div className="value">{language === 'de' ? 'Kurzmitgliedschaft': 'Short-term'}</div>
            </div>
          )}

          <div>
            <button onClick={() => go("personal")}>
              {language === 'de' ? 'Daten bearbeiten' : 'Edit data'}
            </button>
          </div>
        </div>
        <br/>
      </div>


      <FormNavigation {...{navigation, navigationData, language, formData}} />
    </div>
  );
};

export default FormReview;
