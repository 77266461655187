import axios from 'axios';
import * as qs from 'qs';

class MemberService {
  constructor() {
    console.log('Starting member-service...');

    let site_url;
    switch (window.location.hostname) {
      case 'localhost':
        site_url = 'https://www.augaertner.test';
        break;
      case 'join.augaertner-ultimate.at':
        site_url = 'https://www.augaertner-ultimate.at';
        break;
      default:
        site_url = 'https://www.augaertner.test';
    }
    this.api_url = site_url + '/wp-json/aug-members/v1';
    console.log('API-url is: ' + this.api_url);

    // avoid a warning
    axios.interceptors.request.use(function (config) {
      const {headers = {}} = config || {};
      if (headers['User-Agent']) {
        delete config.headers['User-Agent'];
      }

      return config;
    })
  }

  sendNewMemberData(formData) {
    console.log('sending data:', formData);
    return axios.post(`${this.api_url}/createNewMember/`, qs.stringify(formData));
  }

  validateMemberEmail(email) {
    return axios.post(`${this.api_url}/isRegistrationEmailValid/`, qs.stringify({'email': email}));
  }
}

export default MemberService;
